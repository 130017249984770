import Layout from "@app-components/layout.vue";
import NavButton from "@app-components/nav-button/nav-button.vue";
import Spinner from "@app-components/spinner.vue";
import i18nPlugin from "@app-i18n/plugin";
import useAppStore from "@app-store/app";
import { EventAI, trackException, trackUserEvent } from "@app-utilities/app-insights";
import EventsManager from "@app-utilities/events-manager";
import { modalFatal } from "@app-utilities/modals";
import { createDsy, QButton } from "@qamf/lighthouse";
import { debounce } from "lodash-es";
import { RestError } from "scarlett";
import { createApp } from "vue";
import Fragment from "vue-fragment";

import AppEntryPoint from "./app.vue";
import boot from "./boot";
import router from "./router";
import store from "./store";

const BowlerApp = createApp(AppEntryPoint);

BowlerApp.use(Fragment.Plugin);

BowlerApp.component("Spinner", Spinner);
BowlerApp.component("QButton", QButton);
BowlerApp.component("Layout", Layout);
BowlerApp.component("NavButton", NavButton);

const fatalErrors: any[] = [];
let fatalErrorLocation: string | null = null;
const logErrors = debounce(() => {
	const printErrorEl = (el: any, title?: string) => {
		console.groupCollapsed("\x1b[31m%s\x1b[0m", title ?? el.error.message);
		console.info(el.datetime);
		console.error(el.error);
		if (el.extras)
			console.info(el.extras);
		console.groupEnd();
	};
	if (fatalErrors.length === 1)
		printErrorEl(fatalErrors[0], "A fatal error occurred");
	else {
		console.groupCollapsed("\x1b[31m%s\x1b[0m", `Intercepted fatal errors: ${fatalErrors.length}`);
		fatalErrors.forEach(er => printErrorEl(er));
		console.groupEnd();
	}
}, 1000);
let _modalFatalShown = false;
EventsManager.onError(async(error, extras) => {
	if (!fatalErrorLocation)
		fatalErrorLocation = window.location.href;

	const tracked = await trackException(error, extras);
	fatalErrors.push({ error, extras, datetime: new Date() });
	logErrors();

	if (_modalFatalShown) return;

	trackUserEvent(EventAI.FatalError, {
		description: "Unhandled exception feedback to user",
		error: tracked?.exception,
		errorName: tracked?.exception.name,
		errorStack: tracked?.exception.stack,
		errorMessage: tracked?.exception.message,
		errorExtras: tracked?.properties
	});

	_modalFatalShown = true;
	useAppStore().setLoadingView(false);
	await modalFatal();

	if (fatalErrorLocation) {
		location.assign(fatalErrorLocation);
		fatalErrorLocation = null;
	}
});
window.onerror = (message, source, lineno, colno, error) => {
	if (!error) return;
	if (error === "ResizeObserver loop completed with undelivered notifications.") {
		return trackUserEvent(EventAI.ResizePopoverError, {
			description: "Popover content is outside of window"
		});
	}
	EventsManager.emitError(error, { message, source, lineno, colno });
	return true;
};
window.onunhandledrejection = (event: PromiseRejectionEvent) => {
	event.preventDefault();
	if (event.reason && (event.reason instanceof DOMException || event.reason instanceof Error || event.reason instanceof RestError))
		EventsManager.emitError(event.reason);
	else
		EventsManager.emitError(new Error("UnhandledPromiseRejection"), { ...event });
};
BowlerApp.config.errorHandler = (err, vm, info) => {
	EventsManager.emitError(err as Error, { info });
};

const updatePwaThemeColor = () => {
	try {
		const color = localStorage.getItem("themeColorPwa") ?? (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
		const themeColorTag = document.createElement("meta");
		themeColorTag.name = "theme-color";
		themeColorTag.content = color;
		document.head.appendChild(themeColorTag);
	} catch (e) {}
};

if (!window.structuredClone)
	window.structuredClone = (value: any) => JSON.parse(JSON.stringify(value));

window.addEventListener("load", async() => {
	await boot();
	BowlerApp.use(store);
	BowlerApp.use(router);
	BowlerApp.use(i18nPlugin);
	updatePwaThemeColor();
	BowlerApp.use(createDsy());
	BowlerApp.mount("#app-main");

	window.addEventListener("blur", () => trackUserEvent(EventAI.BrowserTabLeave));
	window.addEventListener("focus", () => trackUserEvent(EventAI.BrowserTabResume));
});

export default BowlerApp;
