import { QToast, useToastController } from "@qamf/lighthouse";
import { BToastProps } from "bootstrap-vue-next";
import { Component, defineComponent, h, VNode } from "vue";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface QToastProps extends BToastProps {
	// TODO: Add typing on DSy
}

export type ToastController = ReturnType<typeof useToastController>

let _toastController: ToastController | null = null;

export function initToastUtil(controller: ToastController) {
	_toastController = controller;
}

function createToast(message: string | VNode[], localOptions: QToastProps) {
	return defineComponent({
		setup() {
			return () => h(
				QToast,
				{
					// TODO: Add FIXME about typing on QToastProps
					...localOptions as any
				},
				{
					default: () => message
				}
			);
		}
	});
}

async function launchToast(modalTemplate: Readonly<Component>) {
	if (_toastController === null) return;
	const modal = await _toastController.show?.({
		component: modalTemplate
	});
	return modal;
}

function getDefaultOptions(): QToastProps {
	return {
		noCloseButton: true,
		solid: true
	};
}
export function toastSuccess(message: string | VNode[], overrides?: QToastProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));

	localOptions.variant = "success";
	localOptions.isStatus = true;
	localOptions.toastClass = "toast-signup";
	localOptions.headerClass = "d-none";
	localOptions.noCloseButton = true;

	const messageNodes = typeof message === "string"
		? [h("div", message)]
		: message;
	const messageWrapper = h("div", { class: "message-wrapper" }, messageNodes);
	return launchToast(createToast([messageWrapper], localOptions));
}
