import useCompanyStore from "@app-store/company";
import { getCssVarValue, setCssVarValue } from "@app-utilities/css-variable";
import type { IColorsValues, IThemeValues } from "@app-utilities/interfaces";

const cssVarMapping: Record<keyof IColorsValues, `--${string}`> = {
	MainTextColor: "--body-color",
	HeaderIconsAndTextColor: "--header-color",
	HeaderBackgroundColor: "--header-bg",
	FooterTextColor: "--footer-color",
	FooterBackgroundColor: "--footer-bg",
	HomePageBodyColor: "--home-body-color",
	HomePageBodyBackgroundColor: "--home-body-bg",
	WebOfferButtonColor: "--book-color",
	WebOfferButtonBackgroundColor: "--book",
	ConfirmationButtonsColor: "--primary-color",
	ConfirmationButtonsBackgroundColor: "--primary",
	NavigationArrowsColor: "--nav-text-color",
	NavigationArrowsBackgroundColor: "--nav-bg",
	PriceTagsAndCategoriesColor: "--price-tag-color",
	PriceTagsAndCategoriesBackgroundColor: "--price-tag-bg",
	FontFamily: "--bs-font-sans-serif"
};

function generateColorsMapping(themeValues: IThemeValues): IColorsValues {
	return {
		MainTextColor: themeValues.Colors.Main.Text,
		HeaderIconsAndTextColor: themeValues.Colors.Header.IconsAndText,
		HeaderBackgroundColor: themeValues.Colors.Header.Background,
		FooterTextColor: themeValues.Colors.Footer.Text,
		FooterBackgroundColor: themeValues.Colors.Footer.Background,
		HomePageBodyColor: themeValues.Colors.HomePageBody.Text,
		HomePageBodyBackgroundColor: themeValues.Colors.HomePageBody.Background,
		WebOfferButtonColor: themeValues.Colors.WebOfferButtons.Text,
		WebOfferButtonBackgroundColor: themeValues.Colors.WebOfferButtons.Background,
		ConfirmationButtonsColor: themeValues.Colors.ConfirmationButtons.Text,
		ConfirmationButtonsBackgroundColor: themeValues.Colors.ConfirmationButtons.Background,
		NavigationArrowsColor: themeValues.Colors.NavigationArrows.Text,
		NavigationArrowsBackgroundColor: themeValues.Colors.NavigationArrows.Background,
		PriceTagsAndCategoriesColor: themeValues.Colors.PriceTagsAndCategories.Text,
		PriceTagsAndCategoriesBackgroundColor: themeValues.Colors.PriceTagsAndCategories.Background,
		FontFamily: themeValues.Font.FontFamily
	};
}

function retrieveColorsFromQueryString(): {theme: IThemeValues} | null {
	const queryString = window.location.search;

	const urlParams = new URLSearchParams(queryString);
	const encodedJsonString = urlParams.get("theme");

	if (encodedJsonString) {
		const decodedString = decodeURIComponent(encodedJsonString);
		return JSON.parse(decodedString);
	}

	return null;
}

export function setupColors() {
	let themeValues: {theme: IThemeValues | null} | null = null;
	themeValues = retrieveColorsFromQueryString();
	if (!themeValues) {
		const themeDataFromStore = useCompanyStore().themeValues;
		themeValues = themeDataFromStore ? { theme: themeDataFromStore } : null;
	}

	if (!themeValues || !themeValues.theme) return;

	const colorsAndFont = generateColorsMapping(themeValues.theme);

	for (const [key, value] of Object.entries(colorsAndFont)) {
		const cssVarToModify = cssVarMapping[key];
		const valueOfCssVar = getCssVarValue(cssVarToModify);
		if (valueOfCssVar !== value)
			setCssVarValue(cssVarToModify, value);
	}
}
