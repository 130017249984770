import Bowser from "bowser";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

const before2019 = {
	ie: "<12",
	chrome: "<64",
	edge: "<80",
	firefox: "<58",
	opera: "<51",
	safari: "<12",
	mobile: {
		chrome: "<64",
		safari: "<12",
		samsung_internet: "<6.4"
	}
};

interface IAppAzurePolicies {
	policySignUpSignIn: string
	policyResetPassword: string
	policyEditProfile: string
}
interface IAppAzureConfig extends IAppAzurePolicies {
	instance: string
	clientId: string
	domain: string
	registrationApp: string
}
export type AzureADB2CFlow = keyof IAppAzurePolicies
export type Environment = "Development" | "Staging" | "Expo" | "Production"

type AppLaunchMode = "" | "DevelopSession" | "DevelopAndTestingSession" | "TestingSession";
export interface IAppInstance {
	version: string
	env: string
	channel: string
	website: string
	websiteBeta: string
	launchMode: AppLaunchMode;
	azure: IAppAzureConfig
	connectionString: string
}
interface PWABaseRoute {
	companyId: string;
	systemId: string;
}
type BrowserFeature = "Cookies" | "LocalStorage" | "IndexedDB"
type BrowserFeaturesCheck = Map<BrowserFeature, boolean>

@Module class AppStoreFactory extends VuexModule implements IAppInstance {
	version = "";
	env: Environment = "Production";
	channel: "Beta" | "Stable" = "Stable";
	website = "";
	websiteBeta = "";
	loadingView = true;
	launchMode: AppLaunchMode = "";
	azure: IAppAzureConfig = null as any;
	connectionString = "";
	requiredBrowserFeatures: BrowserFeaturesCheck | null = null;
	browser = Bowser.getParser(window.navigator.userAgent);
	azurePopupWindowIsOpen = false;
	defaultPWABaseRoute: PWABaseRoute | null = null;

	isDevelopSession() {
		return this.launchMode === "DevelopSession" || this.launchMode === "DevelopAndTestingSession";
	}

	isTestingSession() {
		return this.launchMode === "TestingSession" || this.launchMode === "DevelopAndTestingSession";
	}

	isPWASession() {
		return matchMedia("(display-mode: standalone)").matches;
	}

	isValidBrowser() {
		const browser = this.browser;
		if (!browser) return false;

		if (browser.getOSName(true) === "ios") return true;

		if (browser.satisfies(before2019)) return false;
		return true;
	}

	hasAllRequiredBrowserFeatures() {
		if (!this.requiredBrowserFeatures) return;
		let entries = 0;
		let passed = 0;
		for (const [key, value] of this.requiredBrowserFeatures.entries()) {
			entries++;
			if (value) passed++;
		}
		return entries === passed;
	}

	@Mutation updateData(appData: IAppInstance) {
		Object.keys(appData).forEach(prop => {
			this[prop] = appData[prop];
		});
	}

	@Mutation setAzurePopupWindowIsOpen(state: boolean) {
		this.azurePopupWindowIsOpen = state;
	}

	@Mutation setLoadingView(state: boolean) {
		this.loadingView = state;
	}

	@Mutation setRequiredBrowserFeatures(map: BrowserFeaturesCheck) {
		this.requiredBrowserFeatures = map;
	}

	@Mutation setDefaultPWABaseRoute(route: PWABaseRoute) {
		this.defaultPWABaseRoute = route;
	}

	@Action ensurePWAContext(route?: PWABaseRoute) {
		try {
			const storageCompanyId = localStorage.getItem("companyId");
			const storageSystemId = localStorage.getItem("systemId");
			if (this.isPWASession()) {
				if (storageCompanyId && storageSystemId) {
					this.setDefaultPWABaseRoute({
						companyId: storageCompanyId,
						systemId: storageSystemId
					});
				}
			} else if (!this.isPWASession && route && !storageCompanyId && !storageSystemId) {
				localStorage.setItem("companyId", route.companyId);
				localStorage.setItem("systemId", route.systemId);
				this.setDefaultPWABaseRoute(route);
			}
		} catch (e) {}
	}

	@Action async ensureRequiredBrowserFeatures() {
		if (this.requiredBrowserFeatures) return;

		function cookiesCheck() {
			return new Promise<boolean>((resolve) => {
				if (!window.navigator.cookieEnabled)
					resolve(false);
				else {
					try {
						document.cookie = "qamfcheck=1; SameSite=None; Secure";
						const ret = document.cookie.indexOf("qamfcheck=") !== -1;
						if (!ret) resolve(false);

						document.cookie = "qamfcheck=1; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure";
						resolve(true);
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		function localStorageCheck() {
			return new Promise<boolean>((resolve) => {
				if (!("localStorage" in window))
					resolve(false);
				else {
					const key = "qamf";
					const value = "check";
					try {
						localStorage.setItem(key, value);
						localStorage.removeItem(key);
						resolve(true);
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		function indexedDBCheck() {
			return new Promise<boolean>((resolve) => {
				if (!window.indexedDB) resolve(false);
				else {
					try {
						const request = indexedDB.open("user");
						request.onsuccess = () => {
							indexedDB.deleteDatabase("user");
							resolve(true);
						};
						request.onerror = function() { resolve(false) };
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		const featuresMap: BrowserFeaturesCheck = new Map();

		featuresMap.set("Cookies", await cookiesCheck());
		featuresMap.set("LocalStorage", await localStorageCheck());
		featuresMap.set("IndexedDB", await indexedDBCheck());

		this.setRequiredBrowserFeatures(featuresMap);
	}
}

const moduleName = "app";

let AppStore: AppStoreFactory | null;

function useAppStore() {
	if (AppStore) return AppStore;

	const mod = AppStore = new AppStoreFactory({ store, name: moduleName });

	return mod;
}
export default useAppStore;
