<template>
	<div
		class="app-wrapper"
		:view="currentViewName"
	>
		<router-view
			v-if="isErrorView || isFullscreenView"
			class="view"
			:class="{
				'error': isErrorView
			}"
		/>
		<MissingFunctionalities
			v-else-if="isMissingRequiredFunctionalities"
			class="view error"
		/>
		<template v-else-if="isB2CServiceView">
			<q-overlay
				variant="dark"
				class="layout-wrapper"
				:show="isPopupOpen"
				@click="mainOverlayClicked"
			>
				<template #overlay>
					<div />
				</template>
				<Layer
					id="header"
					ref="headerLayer"
					class="header"
					:class="{
						'is-stickying': isSticky,
						'is-disabled': isDisabled
					}"
					@hook:mounted="updateThemeColor"
				>
					<Menubar />
					<ServiceMenu
						v-if="!isMaintenanceModeActive"
						ref="serviceMenu"
					/>
					<Logo
						ref="logo"
					/>
				</Layer>
				<Layer
					id="main"
					class="main"
				>
					<q-overlay
						:show="loadingView"
						no-center
						class="view-overlay"
					>
						<template #overlay>
							<Spinner
								class="view-overlay-spinner"
								size="lg"
							/>
						</template>
						<router-view
							ref="currentView"
							class="view"
						/>
					</q-overlay>
				</Layer>
				<Layer
					id="footer"
					class="footer"
				>
					<Layout v-if="isBookingHome">
						<article
							v-for="el in footerContents"
							:key="el.Index"
							class="footer-contents-wrapper"
							:class="formatClass(el.Index)"
						>
							<h3
								class="footer-contents-title"
								v-html="el.Title"
							/>
							<div
								class="footer-contents-content"
								v-html="el.Content"
							/>
						</article>
					</Layout>
					<Layout>
						<FooterMenu v-if="!isMaintenanceModeActive" />
					</Layout>
					<Layout>
						<Copyright />
					</Layout>
				</Layer>
			</q-overlay>
		</template>
		<template v-else-if="!isSkippableView">
			<Layer
				ref="headerLayer"
				class="qamf-header"
				:class="{ 'is-stickying': isSticky }"
			>
				<Layout><Logo ref="logo" /></Layout>
			</Layer>
			<Layer class="qamf-main">
				<q-overlay
					:show="loadingView"
					no-center
					class="view-overlay"
				>
					<template #overlay>
						<Spinner
							class="view-overlay-spinner"
							size="lg"
						/>
					</template>
					<router-view class="view" />
				</q-overlay>
			</Layer>
			<Layer class="qamf-footer">
				<Layout>
					<Copyright />
				</Layout>
			</Layer>
		</template>
		<Spinner
			v-else
			size="lg"
		/>
		<QToastOrchestrator />
		<QModalOrchestrator />
	</div>
</template>

<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./app.scss" scoped></style>
