import { useAppSettings } from "@qamf/shell-app-sdk";

export function setupFontsFace() {
	const { bowlerBlobFontUrl } = useAppSettings();
	if (!bowlerBlobFontUrl || !bowlerBlobFontUrl.value) return;
	const styleLink = document.createElement("link");
	styleLink.rel = "stylesheet";
	styleLink.href = `${bowlerBlobFontUrl.value}/fonts.min.css`;
	document.head.appendChild(styleLink);
	console.info("Font Css stylesheet appended on head");
}
