export const landing = "reservation-landing";
export const attractionsHome = "reservation-attraction-home";
export const attractionsBook = "reservation-attraction-book";

const attractionsRoutes = [
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/landing",
		name: landing,
		component: () => import("@app-views/book/landing/landing.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/multi-attractions",
		name: attractionsHome,
		component: () => import("@app-views/book/attractions-home/attraction-home.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/attraction-book",
		name: attractionsBook,
		component: () => import("@app-views/book/attraction-book/attraction-book.vue")
	}
];

export default attractionsRoutes;
