import useCompanyRestClient from "@app-rest/company";
import { ICompany } from "@app-rest/company.interfaces";
import useLangStore from "@app-store/lang";
import type { IThemeValues } from "@app-utilities/interfaces";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class CompanyStoreFactory extends VuexModule implements ICompany {
	Id = 0;
	Name = "";
	Language = "";
	themeValues: IThemeValues | null = null;

	@Mutation setCompany(data: ICompany | null) {
		this.Id = data?.Id ?? 0;
		this.Name = data?.Name ?? "";
		this.Language = data?.Language ?? useLangStore().defaultIsoCode;
	}

	@Mutation setThemeValues(data: IThemeValues | null) {
		this.themeValues = data;
	}

	@Action setCompanyData(data: ICompany | null) {
		this.setCompany(data);
	}

	@Action async getThemeValues(systemId: number) {
		const restClient = useCompanyRestClient();
		const themeRestData = await restClient.getFontsAndColors(this.Id, systemId);
		const themeValues = themeRestData.data ?? null;
		this.setThemeValues(themeValues);
	}
}

const moduleName = "company";

let CompanyStore: CompanyStoreFactory | null;

function useCompanyStore() {
	if (CompanyStore) return CompanyStore;

	const mod = CompanyStore = new CompanyStoreFactory({ store, name: moduleName });

	return mod;
}

export default useCompanyStore;
