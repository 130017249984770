export function getCssVarValue(variable: string) {
	return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

export function setCssVarValue(variable: string, value: string) {
	const root = document.documentElement as HTMLElement;
	root.style.setProperty(variable, value);
}

export function retrieveNumberWithoutPx(valueWithPx: string) {
	return Number(valueWithPx.split("px")[0]);
}
