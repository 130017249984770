import { IPolicyTerm, PolicyTermType } from "@app-rest/common.interfaces";
import useCompanyRestClient from "@app-rest/company";
import useSystemRestClient from "@app-rest/systems";
import { IGenericPage } from "@app-rest/systems.interfaces";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";
import { camelCase, flow, upperFirst } from "lodash-es";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "PolicyTermPage",
	setup() {
		const route = useRoute();
		const type = computed(() => {
			return flow(upperFirst)(
				camelCase(route.params.type.toString())
			) as PolicyTermType;
		});
		const data = ref<Partial<IPolicyTerm> | IGenericPage>({});

		const systemStore = useSystemStore();
		const companyStore = useCompanyStore();
		const systemInfoId = systemStore.info?.Id;
		const restClient = useSystemRestClient();

		const getPolicyTerm = async() => {
			if (type.value === PolicyTermType.CookiePolicy) {
				if (!systemInfoId) return;
				return restClient.templateCookiePage(systemInfoId);
			}
			if (type.value === PolicyTermType.PrivacyPolicy) {
				const restClient = useCompanyRestClient();
				return restClient.getPrivacyPolicy(companyStore.Id);
			}
			if (type.value === PolicyTermType.TermsOfUse) {
				if (!systemInfoId) return;
				return restClient.getTermsOfUse(systemInfoId);
			}
			if (type.value === PolicyTermType.TermsAndConditions) {
				if (!systemInfoId) return;
				return restClient.getTermsAndConditions(systemInfoId);
			}
			return Promise.resolve(null);
		};
		const loadContent = async() => {
			const response = await getPolicyTerm();
			data.value = response?.data ?? {};
		};
		watch(
			() => type.value,
			async() => await loadContent()
		);
		onMounted(async() => {
			await loadContent();
			useAppStore().setLoadingView(false);
		});
		return {
			type,
			data,
			getPolicyTerm
		};
	}
});
