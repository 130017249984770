import usei18n from "@app-i18n/index";
import { IPolicyTermMenu, PolicyTermType } from "@app-rest/common.interfaces";
import useCompanyRestClient from "@app-rest/company";
import router from "@app-router/index";
import { policyPage, policyTermPage } from "@app-router/routes/policyTerms";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import { QOverlay } from "@qamf/lighthouse";
import { BNavbar, BNavbarNav, BNavItem } from "bootstrap-vue-next";
import { kebabCase } from "lodash-es";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "FooterMenu",
	components: {
		BNavbar,
		BNavbarNav,
		BNavItem,
		QOverlay
	},
	props: {},
	setup() {
		const systemStore = useSystemStore();
		const route = useRoute();
		const { translateKey } = usei18n();
		const policies = ref<IPolicyTermMenu[] | null>(null);
		const flags = reactive({
			loading: true
		});
		const cookiePolicy = reactive<IPolicyTermMenu>({
			IsEnabled: true,
			Type: PolicyTermType.CookiePolicy,
			UrlLabel: ""
		});
		const hasMinimumData = computed(() => {
			return useLangStore().isoCode && useCompanyStore().Id;
		});
		const defaultLabelVoices = (type: PolicyTermType) => {
			switch (type) {
				case PolicyTermType.CookiePolicy:
					return translateKey("common_cookie_policy");
				case PolicyTermType.TermsAndConditions:
					return translateKey("common_terms_and_conditions");
				case PolicyTermType.PrivacyPolicy:
					return translateKey("common_privacy_policy");
				case PolicyTermType.TermsOfUse:
					return translateKey("common_website_terms_of_use");
			}
		};
		const footerMenu = computed(() => {
			const policiesEntries = policies.value ?? [];
			const terms = systemStore.terms ? systemStore.terms : [];
			return [...policiesEntries, ...terms, cookiePolicy];
		});
		const footerMenuVoices = computed(() => {
			return footerMenu.value
				.filter((link) => link.IsEnabled)
				.map((link) => {
					const name = link.Type === PolicyTermType.PrivacyPolicy ? policyPage : policyTermPage;
					const location = {
						name,
						params: {
							...route.params,
							type: kebabCase(link.Type.toString())
						},
						route: route.query
					};
					const privacyRoute = router.resolve(location);
					return {
						label: link.UrlLabel !== "" ? link.UrlLabel : defaultLabelVoices(link.Type),
						url: privacyRoute.href
					};
				});
		});
		const filterPolicies = (data: IPolicyTermMenu[]) => {
			return data.filter(term => term.Type !== PolicyTermType.CookiePolicy
						&& term.Type !== PolicyTermType.TermsAndConditions
						&& term.Type !== PolicyTermType.TermsOfUse
						&& term.Type !== PolicyTermType.KioskPlayNow);
		};
		onMounted(async() => {
			flags.loading = true;
			await systemStore.ensureTerms();
			const restClient = useCompanyRestClient();
			const response = await restClient.getPolicyList(useCompanyStore().Id);
			if (response.data) {
				const policiesToFilter = response.data;
				const dataFiltered = filterPolicies(policiesToFilter);
				policies.value = dataFiltered;
			}
			flags.loading = false;
		});
		return {
			hasMinimumData,
			flags,
			defaultLabelVoices,
			footerMenu,
			footerMenuVoices
		};
	}
});
