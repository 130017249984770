import useLangStore from "@app-store/lang";
import useRestUtilsStore from "@app-store/rest-utils";
import { useRestClientBuilder } from "scarlett";
export interface IResponseAny {
	<TData = any, TError = any>(prom: Promise<any>): Promise<
		[TData | null, TError | null]
	>;
}

export type ErrorCode<T = string> = T
export interface IResponseError<T = string> {
	Error?: {
		Code: ErrorCode<T>,
		Message: string
	}
}
export interface IRestResponse<T> {
	Elements: T[],
	HasMoreElements: boolean
}
export const resolveAny: IResponseAny = (prom: Promise<any>) => {
	return new Promise<any>(resolve => {
		prom.then((response: any) =>
			resolve([response, null])
		).catch((err: any) => resolve([null, err]));
	});
};

export const useClientBuilder = useRestClientBuilder({
	basePath: "/api",
	throw: true,
	headers: new Headers({
		Pragma: "no-cache",
		"Cache-Control": "no-cache, no-store",
		"Content-Type": "application/json"
	}),
	credentials: "include",
	responseType: (req, res) => {
		if (res?.status === 500)
			return "text";

		return "json";
	},
	// FIXME: Remove this filter when every request failure is handled (via specific status codes and error codes)
	throwExcluding: [{ errorCode: "BodyParse" }],
	onRequest: function(req) {
		const restUtilsStore = useRestUtilsStore();
		if (!req.options.headers)
			req.options.headers = new Headers();
		const headers = req.options.headers;

		const language = useLangStore().isoCode;
		if (language)
			headers.set("Accept-Language", language);

		const token = restUtilsStore.isAccessTokenValid() ? restUtilsStore.accessToken : null;
		if (!token)
			headers.delete("Authorization");
		else
			headers.set("Authorization", `Bearer ${token}`);
	}
});
